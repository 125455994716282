import React from 'react';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import * as Actions from '../../actions';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import {APPLICATION_NAME} from "../../constants";


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'© '}
        OTAMAJAKUSHI
      {' '}
      {new Date().getFullYear()}
      {'. '}
        RABBIT KNOT.
    </Typography>
  );
}

const userStyles = makeStyles(theme => ({
  main: {
    width: '100%',
    height: '100%',

  },
  toolbar: theme.mixins.toolbar,
  loginForm: {
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function Login (props) {

  if (props.auth.isLoggedIn) {
    props.history.push('/');
  }

  const [inputUsername, setInputUsername] = React.useState('');
  const [inputPassword, setInputPassword] = React.useState('');
  const classes = userStyles();
  const handle = () => {
    props.loginAuth(inputUsername, inputPassword);
  };

  const handleOnChangeInput = (e) => {
    switch(e.target.name) {
      case 'email':
        setInputUsername(e.target.value);
        break;
      case 'password':
        setInputPassword(e.target.value);
        break;
      default:
        break;
    }
  };

  return (
    <React.Fragment>
      <Container component={'div'} maxWidth={'xs'}>
        <main className={classes.main}>
        <div className={classes.toolbar}/>
        <div className={classes.paper} >
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            {APPLICATION_NAME}
          </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={inputUsername}
              onChange={handleOnChangeInput}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={inputPassword}
              onChange={handleOnChangeInput}
            />
            <Button
              // type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={handle}
            >
              サインイン
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  パスワードをお忘れの場合
                </Link>
              </Grid>
              <Grid item>
                <Link href="#" variant="body2">
                  {"アカウント登録がお済みでない方"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
        </main>
      </Container>
    </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
  return {
    ...bindActionCreators(Actions, dispatch)
  };
}

export default connect(
    state => state,
    mapDispatchToProps
  )(Login)