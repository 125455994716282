import React from 'react';
import { Route, Switch, Router , Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import Login from './containers/Auth/Login';
import { PrivateRoute } from './containers/Auth/PrivateRoute';
import { history, store } from './_helpers';
import Leaving from './containers/Leaving';
import * as Actions from "./actions";
import {bindActionCreators} from "redux";

function App (props) {
    history.listen((location, action) => {
    });

    if (!props.auth.authUser && props.auth.token) {
        props.loadAuthUser(props.auth.token);
    }

    return (
        <Router history={history}>
            <Switch>
                <Route {...props} exact path="/" render={({ match })=>(<Redirect to={`/leaving`}/>)}/>
                <Route {...props} exact path='/login' component={Login} />
                <Switch>
                    <PrivateRoute {...props} exact path='/leaving' component={Leaving} />
                </Switch>
            </Switch>
        </Router>
    );
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(Actions, dispatch)
    };
}

export default connect(
    state => state,
    mapDispatchToProps
)(App);
