export function dateFormat(date) {
    let d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export function dateFormatSlash(date) {
    const dd = date;
    const YYYY = dd.getFullYear();
    const MM = dd.getMonth()+1;
    const DD = dd.getDate();
    return YYYY + "/" + MM + "/" + DD;
}

export function getDay(date) {
    const WeekChars = [ "日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日" ];
    const dObj = new Date(date);
    const wDay = dObj.getDay();
    return WeekChars[wDay];
}

export function isEmpty(obj) {
    return !Object.keys(obj).length;
}