import {ACTION_TYPE} from '../constants';
import {createMenuItem, reloadGakkyuForBasicMenu} from "../_helpers/appState";

function createGakunen( id, schoolId, gakurei, gakunenName, gakunenNameKana ) {
    return { id, schoolId, gakurei,gakunenName, gakunenNameKana  };
}

function createGakkyu( id, schoolId, gakurei, gakkyuName, gakkyuNameKana, teacher ) {
    return { id, schoolId, gakurei,gakkyuName, gakkyuNameKana, teacher  };
}
const schoolId = '28107001';

const initialState = {
    openDrawer: true,
    basicMenu: [
        createMenuItem('dashboard','TOP','home','/manage',1,[]),
        createMenuItem('gakkyu', '学級', 'libraryBooks', '', 2, []),
        createMenuItem('extendChildCare','預かり保育','accessTime','/manage/extendChildCare',3,[]),
    ],
    extendMenu: [
        // {key:'manageOrders', title:'園バス', iconKey:'accountBox', link:'/manage/orders', index:1, children:[
        //     {key:'dashboard', title:'運行状況', iconKey:'accountBox', link:'/manage', index:1, children:[]},
        //     {key:'dashboard', title:'本日の座席', iconKey:'accountBox', link:'/manage', index:2, children:[]},
        //     {key:'dashboard', title:'バスの設定', iconKey:'accountBox', link:'/manage', index:3, children:[]},
        // ]},
        // {key:'billingInfo', title:'お知らせ配信', iconKey:'accountBox', link:'/manage/billing', index:2, children:[
        //     {key:'dashboard', title:'お知らせ作成', iconKey:'accountBox', link:'/manage', index:1, children:[]},
        //     {key:'dashboard', title:'配信済みお知らせ', iconKey:'accountBox', link:'/manage', index:2, children:[]},
        //     {key:'dashboard', title:'宛先リストの作成・編集', iconKey:'accountBox', link:'/manage', index:3, children:[]},
        // ]},
        // {key:'settingShop', title:'日程調整', iconKey:'accountBox', link:'/setting/shop', index:3, children:[
        //     {key:'dashboard', title:'日程調整を作る', iconKey:'accountBox', link:'/manage', index:1, children:[]},
        //     {key:'dashboard', title:'日程調整を集計', iconKey:'accountBox', link:'/manage', index:2, children:[]},
        //     {key:'dashboard', title:'編集済み日程', iconKey:'accountBox', link:'/manage', index:3, children:[]},
        // ]},
        // {key:'settingClients', title:'アンケート', iconKey:'accountBox', link:'/setting/clients', index:44, children:[
        //     {key:'dashboard', title:'アンケートを作る', iconKey:'accountBox', link:'/manage', index:1, children:[]},
        //     {key:'dashboard', title:'アンケートを集計', iconKey:'accountBox', link:'/manage', index:2, children:[]},
        // ]},
    ],
    gakunen: [
        createGakunen( 1, schoolId, 6, '年長', 'ねんちょう' ),
        createGakunen( 2, schoolId, 5, '年中', 'ねんちゅう' ),
        createGakunen( 3, schoolId, 4, '年少', 'ねんしょう' ),
        createGakunen( 4, schoolId, 3, '未就園(3歳児)', 'みしゅうえん(さんさいじ)' ),
    ],
    gakkyu:[],
};

export default function appState(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.SWITCH_DRAWER:
            return Object.assign({}, state, {
                openDrawer: !state.openDrawer
            });
        case ACTION_TYPE.LOAD_FINISH_GAKKYUS:
            return Object.assign({}, state, {
                gakkyu: action.gakkyus,
                basicMenu: action.basicMenu,
            });
        case ACTION_TYPE.LOAD_ERROR_GAKKYUS:
        default:
            return state;
    }
}