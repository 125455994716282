import {ACTION_TYPE} from "../constants";

// Generate Order Data
function createAbsentData(schoolId, absentDate,  absentStatus, studentId, gakurei, gakkyuId, comment, updated_at) {
    return { schoolId, absentDate,  absentStatus, studentId, gakurei, gakkyuId, comment, updated_at };
}

const initialState = {
    students: [],
    absent:[],
    extendedDaycareRecords:[],
};

export default function students(state = initialState, action) {
    switch(action.type) {
        case ACTION_TYPE.STUDENTS_LOAD_FINISH:
            if (action.students) {
                return Object.assign({}, state, {
                    students: action.students
                });
            }
            return Object.assign({}, state, {});
        case ACTION_TYPE.STUDENTS_LOAD_REQUEST:
            return Object.assign({}, state, {
                studentsIsLoaded: true
            });
        case ACTION_TYPE.REGISTER_EXTEND_DAYCARE_FINISH:
        case ACTION_TYPE.LEAVING_EXTENDED_DAYCARE_FINISH:
        case ACTION_TYPE.LOAD_EXTEND_DAYCARE_FINISH:
            if (action.records) {
                const newRecords = Object.assign({}, state.extendedDaycareRecords);
                newRecords[action.date] = action.records;
                return Object.assign({}, state, {
                    extendedDaycareRecords: newRecords
                });
            }
            return Object.assign({}, state, {});
        default:
            return state;
    }
}