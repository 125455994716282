import { axiosPost } from '../_helpers';
import {API_PATH, ACTION_TYPE} from "../constants";

const loadRequest = () => {return {type: ACTION_TYPE.STUDENTS_LOAD_REQUEST}}
const loadSuccess = res => {return {type: ACTION_TYPE.STUDENTS_LOAD_FINISH, students: res.data.students}}
const loadFailure = err =>  { return {type: ACTION_TYPE.STUDENTS_LOAD_FINISH, error: err}}
export function studentsLoad() {
    return (dispatch) => {
        dispatch(loadRequest());
        return axiosPost( 'students', {})
            .then(res => dispatch(loadSuccess(res))
            ).catch(err => dispatch(loadFailure(err))
            )
    }
}

const registerExtendedDaycareSuccess = res => {return {
    type: ACTION_TYPE.REGISTER_EXTEND_DAYCARE_FINISH,
    date: res.data.date,
    records: res.data.records}};
const registerExtendedDaycareError = err => {return {
    type: ACTION_TYPE.REGISTER_EXTEND_DAYCARE_FINISH,
    error: err}};
export function registerExtendedDaycare(studentId, date, fromTime, toTime, status='reservation', comment='') {
    return dispatch => {
        return axiosPost(API_PATH.registerExtendedDaycareRecords, {
            studentId, date, fromTime, toTime, status, comment})
            .then(res => dispatch(registerExtendedDaycareSuccess(res))
            ).catch(err => dispatch(registerExtendedDaycareError(err)))}}

const loadExSuccess = res => {return {
    type: ACTION_TYPE.LOAD_EXTEND_DAYCARE_FINISH,
    date: res.data.date,
    records: res.data.records}};
const loadExError = err => {return {
    type: ACTION_TYPE.LOAD_EXTEND_DAYCARE_ERROR,
    error: err}};
export function loadExtendedDaycareRecords(studentId, date, status='reservation') {
    return dispatch => {
        return axiosPost(API_PATH.loadExtendedDaycareRecords, {
            studentId, date, status
        }).then(res => dispatch(loadExSuccess(res))
        ).catch(err => dispatch(loadExError(err)))}}

const leavingSuccess = res => { return {
    type: ACTION_TYPE.LEAVING_EXTENDED_DAYCARE_FINISH,
    date: res.data.date,
    records: res.data.records,
}};
const leavingError = err => { return {
    type: ACTION_TYPE.LEAVING_EXTENDED_DAYCARE_ERROR,
    error: err
}}

export function leavingExtendedDaycare(recordId, selectedAt, useTimeMinutes) {
    return dispatch => {
        return axiosPost(API_PATH.leavingExtendedDaycare, {recordId:recordId, toTime:selectedAt, useTime:useTimeMinutes})
            .then(res => dispatch(leavingSuccess(res)))
            .catch(err => dispatch(leavingError(err)))}}