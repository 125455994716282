import React from 'react';

import Inbox from '@material-ui/icons/Inbox';
import Accessibility from '@material-ui/icons/Accessibility';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import AudioTrack from '@material-ui/icons/Audiotrack';
import AccountBox from '@material-ui/icons/AccountBox';
import MoveToInbox from '@material-ui/icons/MoveToInbox';
import Mail from '@material-ui/icons/Mail';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight'
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import Contacts from '@material-ui/icons/Contacts';
import Home from '@material-ui/icons/Home';
import Settings from '@material-ui/icons/Settings';
import ExitToApp from '@material-ui/icons/ExitToApp';
import DateRange from '@material-ui/icons/DateRange';
import PermContactCalendar from '@material-ui/icons/PermContactCalendar';
import SupervisorAccount from '@material-ui/icons/SupervisorAccount';
import AddCircle from '@material-ui/icons/AddCircle';
import AccessTime from '@material-ui/icons/AccessTime';
import Cached from '@material-ui/icons/Cached';


export default function Icon(props) {
    const iconKey = props.iconKey;

    switch(iconKey){
        case 'chevronRight': return (<ChevronRight />);
        case 'expandMore': return (<ExpandMore />);
        case 'expandLess': return (<ExpandLess />);
        case 'mail': return (<Mail />);
        case 'inbox': return (<Inbox />);
        case 'moveToInbox': return (<MoveToInbox />);
        case 'accountBox': return (<AccountBox />);
        case 'audioTrack': return (<AudioTrack />);
        case 'accessibility': return (<Accessibility />);
        case 'keyboardArrowRight': return (<KeyboardArrowRight />);
        case 'keyboardArrowLeft': return (<KeyboardArrowLeft />);
        case 'keyboardArrowUp': return (<KeyboardArrowUp />);
        case 'keyboardArrowDown': return (<KeyboardArrowDown />);
        case 'libraryBooks': return (<LibraryBooks />);
        case 'contacts': return (<Contacts />)
        case 'home': return (<Home />)
        case 'settings': return (<Settings />)
        case 'exitToApp': return (<ExitToApp />)
        case 'dateRange': return(<DateRange />)
        case 'permContactCalendar': return(<PermContactCalendar />)
        case 'supervisorAccount': return(<SupervisorAccount />)
        case 'addCircle': return(<AddCircle />)
        case 'accessTime': return(<AccessTime />)
        case 'cached': return(<Cached />)
        default: return (<Inbox />);
    }
}
