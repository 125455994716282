import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles} from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Icon from "../Accessory/Icon";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import {dateFormat} from "../../_helpers";


const userStyles = makeStyles(theme => ({
  pageNavBarRoot: {
    zIndex: theme.zIndex.drawer + 1,
    flexGrow: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  clock: {
    position: 'relative',
    marginRight: theme.spacing(2),
    marginLeft: 'auto',
  }
}));


function currentClock() {
  const currentTime = new Date();
  return currentTime.toLocaleString();
}

function PageNavBar(props) {
  const classes = userStyles();
  const [currentTime, setCurrentTime] = React.useState(currentClock);

  setInterval(() => {
    const current = currentClock();
    setCurrentTime(current);
  }, 1000);

  const [anchorEl, setAnchorEl] = React.useState(null);
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleClose() {
    setAnchorEl(null);
  }

  function reload() {
    window.location.reload();
    // props.loadExtendedDaycareRecords(null, dateFormat(new Date()), 'reservation');
  }

  return (
    <div key='page-navvar-root' className={classes.pageNavBarRoot}>
      <AppBar position="static" color='secondary' elevation={1}>
        <Toolbar>
          <Button
              aria-controls="customized-menu"
              aria-haspopup="true"
              color="default"
              onClick={reload}>
            <Icon iconKey={'cached'}/>
          </Button>
          <Typography variant="h6" color="inherit">
            {props.title + ' ' + props.pageTitle}
          </Typography>
          <Typography className={classes.clock}>
            {currentTime}
          </Typography>
          <div key='menu-button'>
            <Button
                aria-controls="customized-menu"
                aria-haspopup="true"
                color="default"
                onClick={handleClick}>
              <Icon iconKey={'settings'}/>
            </Button>
            <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                {...props}
            >
              <MenuItem onClick={props.logoutAuth}>
                <ListItemIcon>
                  <Icon iconKey={'exitToApp'}/>
                </ListItemIcon>
                <ListItemText primary="Logout" />
              </MenuItem>
            </Menu>

          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

export default PageNavBar;