import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    type: 'light',
    palette: {
        primary: {
            light: '#bef67a', // 基本の色よりも明るい色
            main: '#8bc34a', // 基本の色
            dark: '#5a9216', // 基本の色よりも暗い色
            contrastText: '#4e342e', // テキストの色
        },
        secondary: {
            light: '#ffd95b',
            main: '#ffa726',
            dark: '#c77800',
            contrastText: '#4e342e',
        },
        item: {
            
        },
    },
});

export default theme;