import React from 'react';
import PageNavBar from '../Common/PageNavBar';

import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import StudentList from "./StudentsList";
import {Typography} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import moment from 'moment';
import {isEmpty} from '../../_helpers/misc';
import { APP_INFO } from "../../constants";
import amber from '@material-ui/core/colors/amber';
import green from '@material-ui/core/colors/green';
import ComfirmDialog from "../Common/ComfirmDialog";
const themeColor = amber;
const subThemeColor = green;

const styles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100vh',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    margin: 0,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignContent: 'stretch',
    backgroundColor: themeColor[50],
  },
  contents: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    margin: 0,
    padding: theme.spacing(2),
  },
  contentsLeft: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  contentsRight: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    height: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  showInformation: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
    verticalAlign: 'middle',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  controlArea: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  selectName: {
    fontSize: '2rem',
    fontWeight: 'bold',
    lineHeight: '5rem',
  },
  selectedAt: {
    fontSize: '2rem',
  },
  careTime: {
    fontSize: '2rem',
  },
  leavingButton: {
    position: 'absolute',
    transform: 'translateX(-50%)',
    width: '90%',
    height: '20%',
    bottom: '10%',
    fontSize: '2rem'
  },
  versionInfo: {
    position: 'absolute',
    right: '.3rem',
    bottom: '.3rem',
    fontSize: '.6rem',

  }
}));

const Home = (props) => {
  const schoolName = props.preSchool.preSchoolName;
  const [record, setRecord] = React.useState({});
  const [selectedAt, setSelectedAt] = React.useState('');
  const [useTimeMinutes, setUseTimeMinutes] = React.useState(0);
  const [comfirmIsOpen, setComfirmIsOpen] = React.useState(false);
  const classes = styles();

  const selectRecord = (record) => {
    setRecord(record);
    const now = moment();
    //仕様注：5分超過までは切り捨ての15分刻みで記録
    const nowMinutes = now.minutes();
    const overTime = nowMinutes%15;
    let loggingTime = '';
    if (overTime > 5) {
      loggingTime = nowMinutes - overTime + 15;
    } else {
      loggingTime = nowMinutes - overTime;
    }
    now.minutes(loggingTime).second(0).millisecond(0);
    setSelectedAt(now.format('HH:mm'));

    const fromTime = moment(record.fromTime, 'HH:mm:ss');
    let useTimeMinutes = now.diff(fromTime, 'minutes');
    if (useTimeMinutes < 0 ) {
      useTimeMinutes = 0;
    }
    setUseTimeMinutes(useTimeMinutes);
  };

  const resetState = () => {
    setRecord({});
    setSelectedAt('');
    setUseTimeMinutes(0);
  };

  const useTimeToLabel = (useMin) => {
    if (isEmpty(record)) return '';
    if (useMin > 0) {
      const minutes = useMin%60;
      const hours = (useMin - minutes)/60;
      return '利用時間 ' + hours + '時間' + minutes + '分';
    } else if (useMin <= 0) {
      return '利用時間 ０時間'
    }
  };

  const handleLeaving = () => {
    if (!isEmpty(record)) {
      props.leavingExtendedDaycare(record.id, selectedAt, useTimeMinutes);
      resetState();
    }
  };


  return (
    <React.Fragment>
      <div className={classes.root}>
        <Container className={classes.container}>
          <PageNavBar {...props} title={schoolName} pageTitle={'預かり保育降園処理'}/>
          <div className={classes.contents}>
            <div className={classes.contentsLeft}>
              <StudentList {...props} themeColor={themeColor} subThemeColor={subThemeColor} selectRecord={selectRecord}/>
            </div>
            <div className={classes.contentsRight}>
              <div className={classes.showInformation}>
                <React.Fragment>
                  <Typography className={classes.selectName}>{record.studentNameKana? record.studentNameKana : ''}</Typography>
                  <Typography className={classes.selectedAt}>{selectedAt? '降園時刻　' + selectedAt : ''}</Typography>
                  <Typography className={classes.careTime}>{useTimeToLabel(useTimeMinutes)}</Typography>
                </React.Fragment>
              </div>
              <div className={classes.controlArea}>
                <Button variant="contained" size="large" color='primary' className={classes.leavingButton} onClick={() => {if (!isEmpty(record)) {setComfirmIsOpen(true)}}}>降園する</Button>
                <ComfirmDialog isOpen={comfirmIsOpen} setIsOpen={setComfirmIsOpen} agreeAction={handleLeaving} text={'降園時刻を登録します'}/>
              </div>
            </div>
          </div>

          <Typography className={classes.versionInfo}>©RABBIT KNOT Inc. OTAMAJAKUSHI ver{APP_INFO.version}</Typography>
        </Container>
      </div>
    </React.Fragment>
  )
};

export default Home;