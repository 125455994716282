// import * as Constants from '../constants';

// Generate Order Data
function createAbsentData(id, name, comment, updated_at) {
  return { id, name ,comment, updated_at };
}

function createExtendChildCare(id, name, comment, startTime, endTime, updated_at) {
  return { id, name, comment, startTime, endTime, updated_at };
}

const initialState = {
  mode: 'show',
  currentDiaryId: '',
  gakkyu:[
    {
      id:'0301',
      gakkyuName: 'さくら',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '円谷耕平', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '谷田陽介', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '山形健太', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '岩手守安', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '秋田実央', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '宮城仙太郎', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0302',
      gakkyuName: 'ばら',
      absents:[
        createAbsentData(0, '荒井公作', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '吉田松次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '北見浩助', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '南悟', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '西島駿太', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '村上太郎', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '吉岡高雄', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '吉田卓夫', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '道田優希', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '山本その子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '早乙女薫', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '町村悠太', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0303',
      gakkyuName: 'ゆり',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '山中三郎', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '吉川五郎', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '結城真弘', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '岸川聡子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '竹田守', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '森川義三', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '桜川優介', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0201',
      gakkyuName: 'すみれ',
      absents:[
        createAbsentData(0, '滝本卓', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '山岡祐慈', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '森下小五郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '藤田優子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '北川薫', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0202',
      gakkyuName: 'あやめ',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0203',
      gakkyuName: 'たんぽぽ',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0101',
      gakkyuName: 'すみれ',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0102',
      gakkyuName: 'あやめ',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    },
    {
      id:'0103',
      gakkyuName: 'たんぽぽ',
      absents:[
        createAbsentData(0, '田中太郎', '風邪のため', '2019-09-01 08:00:12'),
        createAbsentData(1, '鈴木次郎', '腹痛のため', '2019-09-01 08:00:12'),
        createAbsentData(2, '横浜四郎', '病院のため', '2019-09-01 08:00:12'),
        createAbsentData(3, '神戸花子', '怪我のため', '2019-09-01 08:00:12'),
        createAbsentData(4, '須磨西子', '家庭の用事のため', '2019-09-01 08:00:12'),
        ],
      extendChildCare: [
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
        createExtendChildCare(0, '明石朝子', 'わくわくクラブ', '15:00', '17:00', '2019-09-01 08:00:12'),
      ],
    }
  ],
};

export default function pageState(state = initialState, action) {
    switch (action.type) {
        default:
            return state;
    }
}