import { store } from './store';

export const createMenuItem = (key, title, iconKey, link, index, children) => {
    return {key, title, iconKey, link, index, children};
};
function gakunenBy(gakurei) {
    const gakunen = store.getState().appState.gakunen;
    return gakunen.find(g => g.gakurei == gakurei);
}

export function reloadGakkyuForBasicMenu(gakkyu) {
    const basicMenu = store.getState().appState.basicMenu;
    const gakkyuMenu = basicMenu.find(m => m.key === 'gakkyu');
    let indexCount = 1;
    gakkyu.forEach(g => {
        let gakunenMenu = gakkyuMenu.children.find(c => c.key === 'gakurei-'+ g.gakurei);
        if (!gakunenMenu) {
            gakunenMenu = createMenuItem(
                'gakurei-' + g.gakurei,
                gakunenBy(g.gakurei).gakunenName,
                'apps',
                '',
                indexCount,
                []);
            gakkyuMenu.children.push(gakunenMenu);
            indexCount++;
        }
        const gakkyuMenuItem = createMenuItem(
            g.gakurei + '-' + g.id,
            g.name,
            'contacts',
            '/manage/gakkyu/' + g.id,
            g.id,
            []);
        gakunenMenu.children.push(gakkyuMenuItem);
    });
    return basicMenu;
}