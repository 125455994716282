// Auth
export const ACTION_TYPE = {
    LOGIN_FINISH: 'LOGIN_FINISH',
    LOAD_AUTH_USER: 'LOAD_AUTH_USER',
    LOGOUT_AUTH:'LOGOUT_AUTH',
    SIGNUP_AUTH:'SIGNUP_AUTH',
    SWITCH_DRAWER: 'SWITCH_DRAWER',
    RELOAD_GAKKYU: 'RELOAD_GAKKYU',
    LOAD_FINISH_GAKKYUS: 'LOAD_FINISH_GAKKYUS',
    LOAD_ERROR_GAKKYUS: 'LOAD_ERROR_GAKKYUS',
    STUDENTS_LOAD_FINISH: 'STUDENTS_LOAD_FINISH',
    STUDENTS_LOAD_REQUEST: 'STUDENTS_LOAD_REQUEST',
    REGISTER_EXTEND_DAYCARE_FINISH: 'REGISTER_EXTEND_DAYCARE_FINISH',
    LOAD_EXTEND_DAYCARE_FINISH: 'LOAD_EXTEND_DAYCARE_FINISH',
    LEAVING_EXTENDED_DAYCARE_FINISH: 'LEAVING_EXTENDED_DAYCARE_FINISH',
    LEAVING_EXTENDED_DAYCARE_ERROR: 'LEAVING_EXTENDED_DAYCARE_ERROR',
    LOAD_EXTEND_DAYCARE_ERROR: 'LOAD_EXTEND_DAYCARE_ERROR',
}

export const ENVIRONMENT = 'develop'; // staging, production
export const APPLICATION_NAME = 'OTAMAJAKUSHI Leaving';

export const API_BASEPATH = () => {
    switch (ENVIRONMENT) {
        case 'develop':
            return 'https://dev.api.otama.rabbit-knot.co.jp/';
        case 'staging':
            return 'https://staging.api.otama.rabbit-knot.co.jp/';
        case 'production':
            return 'https://api.otama.rabbit-knot.co.jp/';
        default:
            return '';
    }
}

export const API_PATH = {
    baseURL: API_BASEPATH(),
    authentication: 'auth/login',
    refreshToken: 'auth/refresh',
    loadAuthUser: 'auth/load-auth-user',
    loadExtendedDaycareRecords: 'extended-daycare/records',
    registerExtendedDaycareRecords: 'extended-daycare/register',
    loadGakkyus: 'gakkyus',
    leavingExtendedDaycare: 'extended-daycare/leaving',
};

export const APP_INFO = {
    version: '0.1'
}