import axios from 'axios';
import {API_PATH} from "../constants";

export const axiosPost = (uri, data, option) => {
    const token = localStorage.getItem('jwt');
    let headers = {'Content-Type': 'application/json',};

    if (token) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    }
    return axios.post(API_PATH.baseURL + uri, data, {headers: headers});
}