import { combineReducers } from 'redux';
import auth from './Auth';
import preSchool from './PreSchool';
import appState from './AppState';
import pageState from './PageState';
import students from './Students';

const reducer = combineReducers({
    auth,
    preSchool,
    appState,
    pageState,
    students
});

export default reducer;