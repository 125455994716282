import {ACTION_TYPE} from "../constants";

const jwt = localStorage.getItem('jwt');
const initialState = {
    isLoggedIn: false,
    token: jwt ? jwt : null,
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case ACTION_TYPE.LOGIN_FINISH:
            if (action.token) {
                return Object.assign({}, state, {
                    isLoggedIn: true,
                    token: action.token,
                });
            } else {
                return Object.assign({}, state, {
                    isLoggedIn: false,
                    error: action.err
                });
            }
        case ACTION_TYPE.LOAD_AUTH_USER:
            if (action.user) {
                return Object.assign({}, state, {
                    isLoggedIn: true,
                    authUser: action.user,
                    token: action.token,
                });
            } else {
                return Object.assign({}, state, {
                    isLoggedIn: false,
                    error: action.err
                });
            }
        case ACTION_TYPE.LOGOUT_AUTH:
            return {};
        default:
            return state;
    }
}