import { history, isEmpty, axiosPost } from '../_helpers';
import {API_PATH, ACTION_TYPE} from "../constants";

const loginSuccess = res => {return {
    type: ACTION_TYPE.LOGIN_FINISH,
    token: res.data.token,}};
const loginError = err => {return {type: ACTION_TYPE.LOGIN_FINISH, error: err}};
export const loginAuth = (email, password) => {
    return dispatch => {
        return axiosPost(API_PATH.authentication, {email: email, password: password})
            .then(res => {
                localStorage.setItem('jwt', res.data.token);
                dispatch(loginSuccess(res));
                history.push('/');
                }
            ).catch(err => dispatch(loginError(err)))
    }
};

const loadAuthUserSuccess = res => {return {
        type: ACTION_TYPE.LOAD_AUTH_USER,
        user: res.data.user,
        token: res.data.token,}};
const loadAuthUserError = err => {return {type: ACTION_TYPE.LOAD_AUTH_USER, error: err}};
export const loadAuthUser = (token) => {
    return dispatch => {
        return axiosPost(API_PATH.loadAuthUser, {})
            .then(res => {
                    dispatch(loadAuthUserSuccess(res));
                    localStorage.setItem('jwt', res.data.token)
                }
            ).catch(err => {
                dispatch(loadAuthUserError(err));
                dispatch(logoutAuth());
            })
    }
}

export const logoutAuth = () => {
    localStorage.clear();
    return {
        type: ACTION_TYPE.LOGOUT_AUTH,
    }
};