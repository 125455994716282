import React from 'react';
import LeavingHome from '../components/LeavingPage/Home';
import { connect } from 'react-redux';
import {bindActionCreators} from "redux";
import * as Actions from "../actions";

import {store, dateFormat} from "../_helpers";
//
// function reloadState() {
//     store.dispatch(loadExtendedDaycareRecords(dateFormat(new Date())));
// }
//
// reloadState();
//
// setInterval(() => {
//     reloadState();
// }, 5 * 60 * 1000);

function initializeState() {
    // store.dispatch(Actions.studentsLoad());
    store.dispatch(Actions.loadGakkyus());
    store.dispatch(Actions.loadExtendedDaycareRecords(null, dateFormat(new Date()), 'reservation'));
}

initializeState();

function Leaving(props) {

    console.log(props);
  return (
      <React.Fragment>
        <LeavingHome {...props} />
      </React.Fragment>
  );
}

function mapDispatchToProps(dispatch) {
    return {
        ...bindActionCreators(Actions, dispatch)
    };
}

// export default Leaving;

export default connect(
    state => state,
    mapDispatchToProps
)(Leaving);