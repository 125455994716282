import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {dateFormat} from "../../_helpers";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'auto',
        border: 'solid 2px gray',
        borderRadius: '.5rem',
        backgroundColor: 'white'
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    listItem: {
        height: '4rem',
    },
    listText: {
        height: '4rem',
        paddingTop: '1rem',
    },
    subHeader: {
        height: '2rem',
        lineHeight: '2rem',
        backgroundColor: 'pink',
    },
    gakkyuBadge: {
        backgroundColor: 'pink',
        borderRadius: '.2rem',
        fontSize: '.8rem',
        paddingTop: '.2rem',
        paddingBottom: '.2rem',
        paddingRight: '.8rem',
        paddingLeft: '.8rem',
        width: '20%',
        position: 'absolute',
        textAlign: 'center',
    },
    studentName: {
        position: 'absolute',
        paddingTop: '.2rem',
        paddingBottom: '.2rem',
        left: '30%',
        width: '80%',
    }
}));

export default function PinnedSubheaderList(props) {
    const classes = useStyles();

    function createSt(fromTime, gakkyuId, studentId, studentNameKana) {
        return {fromTime, gakkyuId, studentId, studentNameKana};
    }
    const gakkyu = props.appState.gakkyu;
    const exRecords = props.students.extendedDaycareRecords;
    const records = exRecords[dateFormat(new Date())] ? exRecords[dateFormat(new Date())]: [];

    function selectRecord(e, record) {
        props.selectRecord(record);
    }

    function recordsByGakkyuId(gakkyuId) {
        return records.filter(r => r.gakkyuId == gakkyuId);
    }

    return (
        <List className={classes.root} subheader={<li />}>
            {gakkyu.map(g => {
                const gakkyuRecords = recordsByGakkyuId(g.id);
                if (gakkyuRecords.length > 0) {
                return (
                    <li key={`section-${g.id}`} className={classes.listSection}>
                        <ul className={classes.ul}>
                            <ListSubheader className={classes.subHeader}>{g.nameKana}</ListSubheader>
                            {gakkyuRecords.map(record => {
                                return (
                                    <ListItem button className={classes.listItem}
                                              key={`record-${g.id}-${record.studentId}`}
                                              onClick={e => selectRecord(e, record)}>
                                        <ListItemText className={classes.listText}><span
                                            className={classes.gakkyuBadge}>{g.nameKana}</span><span
                                            className={classes.studentName}>{record.studentNameKana}</span></ListItemText>
                                    </ListItem>);
                            })}
                        </ul>
                    </li>)}
            })}
        </List>
    );
}